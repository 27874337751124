import React from "react"
import LayoutContainer from "../components/layout"
import Breadcrumb from "../components/breadcrumb"

import { graphql } from "gatsby"

import { richTextToReact } from "../utilities/richTextRenderer"

export const inspirationQuery = graphql`
  query Inspiration($id: String) {
    contentfulInspiration(id: { eq: $id }) {
      contentful_id
      title
      content {
        json
      }

      journey {
        color
        illustration {
          file {
            url
          }
        }
      }
    }
  }
`

const InspirationTemplate = ({ data, location }) => {
  const { title, content, journey } = data.contentfulInspiration

  let backgroundColor = "white"
  let illustration = null

  if (journey) {
    backgroundColor = journey[0].color
    illustration = journey[0].illustration.file.url
  }

  let htmlContent = null
  if (content && content.json) {
    htmlContent = richTextToReact(content.json)
  }

  return (
    <LayoutContainer
      path={location.pathname}
      style={{ backgroundColor: backgroundColor }}
    >
      <Breadcrumb location={location} />
      <article>
        <div className="row">
          <div className="col l8 offset-l2 s12">
            {illustration && (
              <img className="activity__image" src={illustration} alt="" />
            )}

            <h1 className="big-title uppercase">{title}</h1>
            <div className="content content__inspiration text">
              {htmlContent}
            </div>
          </div>
        </div>
      </article>
    </LayoutContainer>
  )
}

export default InspirationTemplate
